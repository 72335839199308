import React, { Component } from 'react'
import Page from '../index'

export default class EN extends Component {
  componentDidMount() {
    if (window.localStorage) {
      window.localStorage.setItem('lang', 'en')
    }
  }
  render() {
    return <Page prefix="en" />
  }
}
